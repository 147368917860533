<template>
	<div>
		
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h4>Material Transfer Report {{ total ? `(${total})` : ''}}</h4>
			</CCol>
	     		 <CCol lg="6">
		      		 <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
							v-if="config.getPermission('mts_reports').download"
							size="sm" style="margin-right: 10px" 
							color="primary" 
							@click="download"
							class="float-lg-right">
							<i class="fa fa-download">
							</i>Download
					</CButton>
			         
			       
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			
	      	
		      <CCol lg="12" >  
				<div class="table-responsive-sm table-responsive-md table-responsive-lg">
	      		<CDataTable
				  :loading="isLoading"
			      	:items="dataList.data"
			      	:fields="fields"
					hover
					striped
					outlined
					
					
			    >  
				<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
					<!-- <template #assettype="{item}">
						<td style="padding: 5px; width: 100px; ">
    						<div v-for="(row, index) in item.material_transfer_item">
    							•&nbsp;&nbsp;{{row.asset_type}}
    							
    						</div>
 						</td>
			      	</template>
			      	<template #assetno="{item}">
						<td style="padding: 5px; width: 100px; ">
    						<div v-for="(row, index) in item.material_transfer_item">
    							•&nbsp;&nbsp;{{row.no}}
    							
    						</div>
 						</td>
			      	</template> -->
			      	<!-- <template #qty="{item}">
						<td style="padding: 5px; width: 100px; ">
    						<div v-for="(row, index) in item.material_transfer_item">
    							•&nbsp;&nbsp;{{row.qty}}
    							
    						</div>
 						</td>
			      	</template> -->
					<!-- <template #particular="{item}">
						<td style="padding: 5px; width: 100px; ">
    						<div v-for="(row, index) in item.material_transfer_item">
    							•&nbsp;&nbsp;{{row.particular  != null && row.particular  != "" ? row.particular  : "N/A"}}
    							
    						</div>
 						</td>
			      	</template>
			      	<template #brand="{item}">
						<td style="padding: 5px; width: 100px; ">
    						<div v-for="(row, index) in item.material_transfer_item">
    							•&nbsp;&nbsp;{{row.brand != null && row.brand != "" ? row.brand : "N/A"}}
    							
    						</div>
 						</td>
			      	</template>
			      	<template #serialno="{item}">
						<td style="padding: 5px; width: 100px; ">
    						<div v-for="(row, index) in item.material_transfer_item">
    							•&nbsp;&nbsp;{{row.serial_no != null && row.serial_no != "" ? row.serial_no : "N/A"}}
    							
    						</div>
 						</td>
			      	</template> -->

			    	<!-- <template #action="{item}">
				        <td style="padding: 5px; width: 100px">
				        	<CDropdown 
				                toggler-text="Select"  
								size="sm"
				                color="info"
				            >
				            	<CDropdownItem @click="viewModal(item)">View</CDropdownItem>  
				            	<CDropdownItem @click="updateModal(item)">Update</CDropdownItem>  
								<CDropdownItem @click="opentransferModal(item)">Transfer Equipment</CDropdownItem></CDropdownItem>  
								<CDropdownItem @click="openhistoryModal(item)">Equipment History</CDropdownItem>  
				            	<CDropdownItem @click="deleteItem(item)">Delete</CDropdownItem>   
								
				            </CDropdown>
				        </td>
			      	</template>  -->
			    </CDataTable> 
			    <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(dataList.total / 20)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        /> 
			</div>
	      	</CCol>
	    </CRow>  
		</CCard>
	</div>
</template>
<script> 
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './mts_search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'; 
import moment from 'moment';
import '../style.css';

export default {
	mounted(){  
		
		this.getData();  
	},
	data(){
		return{
			config,
			isLoading: false,
			// historyModal: false,
			// transferModal: false,
			// dispatchModal: false,
			// deliverModal: false,
			// completeModal: false,
			formModal: false,
			showViewModal: false,
			dataList: [],  
			total : 0,



			depotName: "", 
			fields: [
				{
					key: 'mts_no', 
					label: 'RMTS No.'
				},
				{
					key: 'booking_no', 
					label: 'Booking #'
				},
				{
					key: 'transfer_date', 
					label: 'Transfer Date'
				},
				
				{
					key: 'origin', 
					label: 'Origin'
				}, 
				{
					key: 'destination', 
					label: 'Destination'
				},  
				// {
				// 	key: 'dept', 
				// 	label: 'Dept/Project'
				// },
				// {
				// 	key: 'asset_no', 
				// 	label: 'Ref No.'
				// },
				{
					key: 'assettype', 
					label: 'Asset Type'
				},
				{
					key: 'assetno', 
					label: 'Asset No'
				},
				{
					key: 'qty', 
					label: 'Qty'
				},  
				{
					key: 'particular', 
					label: 'Particular'
				},
				{
					key: 'brand', 
					label: 'Brand'
				},
				{
					key: 'serial_no', 
					label: 'Serial No'
				},
				// {
				// 	key: 'remarks', 
				// 	label: 'Remarks',
				// }, 
				
				// {
				// 	key: 'prepared_by', 
				// 	label: 'Prepared By'
				// },
				// {
				// 	key: 'approved_by', 
				// 	label: 'Approved By'
				// }, 
			],
			currentPage: 1,  


			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				mts_no: "",
	            origin_id: "",
	            destination_id: "",
	            dept_id: "",
	            transfer_date_from: moment(new Date()).subtract(6, 'month')._d,
	            transfer_date_to: new Date(),
	            asset_no: "", 
			},
			
		}
	},
	name: 'Tables',
	components: { Search, Datepicker, vSelect},
	methods: {
		 getBadge (status) {
    	return status === 'operational' ? 'success'
       		 : status === 'breakdown' ? 'dark'
         	 : status === 'for scrap' ? 'warning'
			 : status === 'standby' ? 'secondary'
			 : status === 'for erection' ? 'primary'
         	 : status === 'for inspection' ? 'info'
			 : status === 'dismantle' ? 'light'
			 :status === 'for rehab' ? 'success'
			 :status === 'pull-out' ? 'success'
             : status === 'reserve' ? 'danger' : 'primary'
    },
		reloadData(){
			this.getData();  
		},
		updatePage(data){
			this.currentPage = data;
			this.getData();
		},    

	    toggleModal(){
	    	this.formModal = !this.formModal;
	    }, 

	    search(event){
	    	this.filter = event; 
	    	this.getData();  
	    }, 
		openhistoryModal(item){
	    	this.historyModal = true;
	    	this.selectedRow = item;
	    },
 		 opentransferModal(item){
	    	this.transferModal = true;
	    	this.selectedRow = item;
	    },
	    getData(){   
			this.isLoading=true;


			var transfer_date_from = this.filter.transfer_date_from;
			if(transfer_date_from != ""){
	    		transfer_date_from = moment(transfer_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var transfer_date_to = this.filter.transfer_date_to;
			if(transfer_date_to != ""){
	    		transfer_date_to = moment(transfer_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "all" || origin_id == null){
	    		origin_id = ""
	    	} 
	    	var destination_id = this.filter.destination_id;
	    	if(destination_id == "all" || destination_id == null){
	    		destination_id = ""
	    	}
	    	var dept_id = this.filter.dept_id;
	    	if(dept_id == "all" || dept_id == null){
	    		dept_id = ""
	    	}  
	    	axios.get(config.api_path+'/materialTransfer',{
				params : {
					mts_no : this.filter.mts_no,
					transfer_date_from,
					transfer_date_to,
					origin_id,
					destination_id,
					dept_id,
					asset_no : this.filter.asset_no ?? '',
					page : this.currentPage,
					limit : 20
				}
			})
	    	.then(response => {
	    		this.dataList = response.data; 
				this.total = response.data.total;
				console.log(response.data)
	    		this.dataList.data = this.dataList.data.map((value, index)=>{ 
	    			// value.booking_no = value.booking ? value.booking.booking_no : "N/A"; 
	    			value.origin = value.origin ? value.origin : "-"; 
	    			value.destination = value.destination ? value.destination : "-"; 
	    			value.dept = value.dept ? value.dept.customer_name : "-"; 
	    			value.remarks = value.remarks != "" ? value.remarks : "-";
	    			value.prepared_by = value.prepared_by != "" ? value.prepared_by : "-";
	    			value.approved_by = value.approved_by != "" ? value.approved_by : "-";  
					value.particular = value?.particular ? this.$options.filters.truncate(value.particular):'-';
					// value.transfer_date = moment.unix(value.transfer_date).format('MM/DD/YYYY') ? value.transfer_date : "-";
	    			value.transfer_date = value.transfer_date ? moment.unix(value.transfer_date).format('MM/DD/YYYY') : "-"; 
					// value.serial_no = value.serial_no ? value.serial_no : '-';
					value.serial_no = value?.serial_no ? this.$options.filters.truncate(value.serial_no):'-';
					value.brand = value.brand ? value.brand : '-';

	    			return value;
	    		}); 
	    	})
				.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },  

	   

	  

	    download(){ 
	    	var transfer_date_from = this.filter.transfer_date_from;
			if(transfer_date_from != ""){
	    		transfer_date_from = moment(transfer_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var transfer_date_to = this.filter.transfer_date_to;
			if(transfer_date_to != ""){
	    		transfer_date_to = moment(transfer_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "all" || origin_id == null){
	    		origin_id = ""
	    	} 
	    	var destination_id = this.filter.destination_id;
	    	if(destination_id == "all" || destination_id == null){
	    		destination_id = ""
	    	}
	    	var dept_id = this.filter.dept_id;
	    	if(dept_id == "all" || dept_id == null){
	    		dept_id = ""
	    	}  

			this.$showLoading(true)

	    	axios.get(config.api_path+'/reports/materialTransfer?mts_no='+this.filter.mts_no+'&transfer_date_from='+transfer_date_from+'&transfer_date_to='+transfer_date_to+'&origin_id='+origin_id+'&destination_id='+destination_id+'&dept_id='+dept_id+'&asset_no='+this.filter.asset_no+'&page='+this.currentPage+'&limit=20') 
	    	.then(response=>{
				this.$showLoading(false)
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 

					
				setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);

	    	})
			.catch(err => {
				this.$showLoading(false)
			})
	    } 

 	}
}
</script>
